<template>
  <div>
    <div v-if="title && title !== ''" class="title">
      <h1>{{ title }}</h1>
      <f7-link v-if="mode == 'GROUPBUY'" :href="`/shop/product/list/?mode=GROUPBUY`">
        {{ $t.getTranslation("LBL_VIEW_ALL") }}
        <font-awesome-icon :icon="['fas', 'chevron-right']" fixed-width />
      </f7-link>

      <f7-link v-else-if="mode == 'FLASHSALE'" :href="`/shop/product/list/?mode=FLASHSALE`">
        {{ $t.getTranslation("LBL_VIEW_ALL") }}
        <font-awesome-icon :icon="['fas', 'chevron-right']" fixed-width />
      </f7-link>

      <f7-link v-else-if="mode == 'SPECIAL_PRICE'" :href="`/shop/product/list/?mode=SPECIAL_PRICE`">
        {{ $t.getTranslation("LBL_VIEW_ALL") }}
        <font-awesome-icon :icon="['fas', 'chevron-right']" fixed-width />
      </f7-link>

      <f7-link v-else :href="`/shop/product/list/`">
        {{ $t.getTranslation("LBL_VIEW_ALL") }}
        <font-awesome-icon :icon="['fas', 'chevron-right']" fixed-width />
      </f7-link>
    </div>

    <section v-if="options === true" class="options-container">
      <div class="container no-padding-bottom" :class="{ flexRight: hideCategory }">
        <div v-if="!hideCategory && ['SPECIAL_PRICE'].indexOf(mode) < 0" class="categories">
          <f7-link class="no-ripple" @click="selectCategory">
            {{ selectedCategory?.CategoryName ? selectedCategory?.CategoryName : $t.getTranslation("LBL_ALL_CATEGORIES") }}
            <font-awesome-icon :icon="['fas', 'chevron-down']" fixed-width />
          </f7-link>
        </div>

        <div v-if="['SPECIAL_PRICE'].indexOf(mode) >= 0" class="special-price-title">
          {{ $t.getTranslation("LBL_618_SPECIAL_PRICE") }}
        </div>

        <div class="actions">
          <f7-link @click="showSortPopUp">
            <font-awesome-icon :icon="['far', 'arrow-down']" fixed-width />
          </f7-link>
          <f7-link @click="showFilterPopUp"><font-awesome-icon :icon="['far', 'filter']" fixed-width /></f7-link>

          <f7-link v-if="selectedView === 'LIST' && !(mode == 'SPECIAL_PRICE')" @click="changeView('GRID')"><font-awesome-icon :icon="['fas', 'th']" fixed-width /></f7-link>
          <f7-link v-if="selectedView === 'GRID' && !(mode == 'SPECIAL_PRICE')" @click="changeView('LIST')"><font-awesome-icon :icon="['fas', 'th-list']" fixed-width /></f7-link>
        </div>
      </div>
    </section>

    <section v-if="options === true" class="options-container">
      <div class="container no-padding-top no-padding-bottom">
        <f7-link class="no-ripple" @click="selectShipping">
          <span v-if="selectedShippingMethod == 'CROSS_BORDER'">
            {{ $t.getTranslation("LBL_CROSS_BORDER_SHIPPING") }}
          </span>
          <span v-else-if="selectedShippingMethod == 'BONDED'">
            {{ $t.getTranslation("LBL_BONDED_WAREHOUSE_DELIVERY") }}
          </span>
          <span v-else>
            {{ $t.getTranslation("LBL_SELECT_SHIPPING") }}
          </span>
          <font-awesome-icon :icon="['fas', 'chevron-down']" fixed-width />
        </f7-link>

        <div class="actions"></div>
      </div>
    </section>

    <template v-if="productList && productList.length > 0">
      <div class="product-container">
        <div v-if="selectedView === 'LIST'" class="container" :class="{ fullWidthContainer: countryCode }">
          <template v-for="product in productList" :key="'pk_' + product.ProductKey">
            <Suspense>
              <template #default>
                <ProductListCardComponent v-if="product?.Name" :data="product" :isShop="true" />
              </template>
              <template #fallback> </template>
            </Suspense>
          </template>
        </div>

        <div v-else-if="selectedView === 'SWIPER' && productList.length > 2" class="container">
          <f7-swiper v-if="productList && productList.length > 0" :slides-per-view="'auto'" navigation :space-between="10" :autoplay="{ delay: 5000 }" loop :auto-height="true">
            <f7-swiper-slide v-for="product in productList" :key="'pk_' + product.ProductKey">
              <Suspense>
                <template #default>
                  <ProductGridCardComponent v-if="product?.Name" :data="product" :mode="mode" />
                </template>
                <template #fallback> </template>
              </Suspense>
            </f7-swiper-slide>
          </f7-swiper>
        </div>

        <div v-else class="container" :class="{ fullWidthContainer: countryCode }">
          <masonry-layout id="productMasonry" cols="2" gap="10">
            <template v-for="product in productList" :key="'pk_' + product.ProductKey">
              <Suspense>
                <template #default>
                  <ProductGridCardComponent v-if="product?.Name" :data="product" :mode="mode" />
                </template>
                <template #fallback> </template>
              </Suspense>
            </template>
          </masonry-layout>
        </div>
      </div>

      <div v-if="isFetching" class="product-loader">
        <f7-preloader></f7-preloader>
        <h3>{{ $t.getTranslation("LBL_LOADING_PRODUCTS") }}</h3>
      </div>
    </template>

    <Suspense>
      <CategorySelectionPopUpComponent :open="openCategoryPopup" @closed="onCloseCategoryPopup" @selected="onCategorySelected" @cleared="onCategoryCleared" />
    </Suspense>
    <Suspense>
      <ShippingMethodPopUpComponent :open="openShippingPopup" @closed="onCloseShippingPopup" @selected="onShippingSelected" @cleared="onShippingCleared" />
    </Suspense>
  </div>

  <!-- <div v-else-if="productList?.length <= 0 && isFetching && showMainLoading" class="main-fetching">
    <f7-preloader></f7-preloader>
  </div> -->

  <Suspense v-if="productList?.length <= 0 && !isFetching && showNotFoundComponent">
    <NoDataFoundComponent
      v-if="productList?.length <= 0 && !isFetching && showNotFoundComponent"
      :size="'sm'"
      type="full"
      :title="$t.getTranslation('LBL_PRODUCTS_EMPTY')"
      :button="true"
      :button-text="$t.getTranslation('LBL_CONTACT_CS')"
      button-link="/chat/"
    />
  </Suspense>

  <ProductFilterPopUpComponent :isCountryFilter="isCountryFilter" :open="openFilterPopup" @closed="onCloseFilterPopup" @selected="onFilterSelected" @cleared="onFilterCleared" />
  <ProductListSortComponent :open="openSortPopup" @closed="onCloseSortPopup" @selected="onSortSelected" />
</template>

<script>
import { defineComponent, ref, onMounted, computed, defineAsyncComponent } from "vue";
import { configs, extendedConfigs } from "@/utils/configs.js";
import "@appnest/masonry-layout";
import { useStore } from "@/store";
import { get } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";

// import ProductGridCardComponent from "@/components/cards/ProductGridCardComponent.vue";
// import ProductListCardComponent from "@/components/cards/ProductListCardComponent.vue";
// import CategorySelectionPopUpComponent from "@/components/CategorySelectionPopUpComponent.vue";
// import ProductFilterPopUpComponent from "@/components/ProductFilterPopUpComponent.vue";
// import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";
// import ProductListSortComponent from "@/components/ProductListSortComponent.vue";

export default defineComponent({
  name: "ProductListComponent",
  components: {
    ProductGridCardComponent: defineAsyncComponent(() => import(/* webpackChunkName: "product-grid" */ /* webpackMode: "lazy" */ "@/components/cards/ProductGridCardComponent.vue")),
    ProductListCardComponent: defineAsyncComponent(() => import(/* webpackChunkName: "product-list" */ /* webpackMode: "lazy" */ "@/components/cards/ProductListCardComponent.vue")),
    CategorySelectionPopUpComponent: defineAsyncComponent(() => import(/* webpackChunkName: "category-selection" */ /* webpackMode: "lazy" */ "@/components/CategorySelectionPopUpComponent.vue")),
    ShippingMethodPopUpComponent: defineAsyncComponent(() => import(/* webpackChunkName: "shipping-method" */ /* webpackMode: "lazy" */ "@/components/ShippingMethodPopUpComponent.vue")),
    ProductFilterPopUpComponent: defineAsyncComponent(() => import(/* webpackChunkName: "product-filter" */ /* webpackMode: "lazy" */ "@/components/ProductFilterPopUpComponent.vue")),
    NoDataFoundComponent: defineAsyncComponent(() => import(/* webpackChunkName: "no-data" */ /* webpackMode: "lazy" */ "@/components/NoDataFoundComponent.vue")),
    ProductListSortComponent: defineAsyncComponent(() => import(/* webpackChunkName: "product-sort" */ /* webpackMode: "lazy" */ "@/components/ProductListSortComponent.vue")),
  },
  props: {
    title: { type: String, default: "" },
    supplierCode: { type: String, default: "" },
    showNotFoundComponent: { type: Boolean, default: false },
    showMainLoading: { type: Boolean, default: false },
    mode: String,
    layout: String,
    options: Boolean,
    size: { type: Number, default: 16 },
    f7route: Object,
    countryCode: { type: String, default: null },
    returnDataCount: Function,
    setCategory: Function,
    isLoader: { type: Boolean, default: false },
    hideCategory: { type: Boolean, default: false },
    isCountryFilter: { type: Boolean, default: true },
  },
  setup(props) {
    const store = useStore();

    const languageCode = computed(() => store.getters["translation/getLanguage"]);
    const userData = computed(() => store.getters["user/getData"]);
    const pageFilterData = computed(() => store.getters["page/getFilterData"]);

    let page = 1;
    let lastPage = 1;
    let currentMode = props.mode;
    let afterSearch = ref("");

    const isFetching = ref(false);
    const searchValue = ref("");
    const selectedFilterType = ref("");
    const selectedFilterValue = ref([]);
    const selectedCategory = ref("");
    const selectedView = ref("GRID");
    const productList = ref([]);
    const sortValue = ref(null);
    const sortField = ref(null);
    const openSortPopup = ref(false);
    const selectedWinery = ref(null);
    const selectedShippingMethod = ref(null);

    let RegionId = ref("");
    let SubRegionId = ref("");

    const getData = async (category, reset = false) => {
      if (isFetching.value) return;
      isFetching.value = true;

      if (props?.isLoader) {
        helpers.showLoader(true);
      }

      if (reset) {
        page = 1;
        lastPage = 1;
        productList.value = [];
      }

      if (props?.countryCode == userData.value?.Country?.CountryCode && helpers.isBlank(RegionId.value) && helpers.isBlank(SubRegionId.value)) {
        //if the same then pass the region id and sub region id..
        RegionId.value = userData.value?.Country?.RegionId;
        SubRegionId.value = userData.value?.Country?.SubRegionId;
      }

      let payload = {
        mode: currentMode,
        page: page,
        size: props.size,
        SearchValue: searchValue.value,
        RegionId: RegionId.value || "",
        SubRegionId: SubRegionId.value || "",
        SupplierCode: props.supplierCode || "",
        LanguageCode: languageCode.value,
        CountryCode: props.countryCode || "",
        WineryCode: selectedWinery.value && selectedWinery.value != "ALL" ? selectedWinery.value : "",
        afterSearch: page > 1 ? afterSearch.value : "",
      };

      if (!extendedConfigs?.isElastic) {
        payload["SearchField"] = "MULTIPLE";
        payload["SearchType"] = "LIKE";
        payload["FilterType"] = selectedFilterType.value || "";
        payload["FilterValue"] = selectedFilterValue.value;
      }

      if (extendedConfigs?.isElastic) {
        for (const item of selectedFilterValue.value) {
          payload[item.FilterType] = item.FilterValue;
        }
      }

      if (selectedCategory.value?.CategoryCode) {
        payload.CategoryValue = selectedCategory.value.CategoryCode;
      }

      //this is remove for this commit https://cocoho-ph.atlassian.net/browse/PW-701
      // if (category) {
      //   payload.CategoryValue = category;
      // }

      if (!helpers.isBlank(sortField.value) && !helpers.isBlank(sortValue.value)) {
        payload.SortingField = sortField.value;
        payload.SortingValue = sortValue.value;
        payload.IsSort = 1;
      }

      if (selectedShippingMethod.value == "CROSS_BORDER") {
        payload.WareHouseType = "CROSS_BORDER";
      }

      if (selectedShippingMethod.value == "BONDED") {
        payload.WareHouseType = "BONDED";
      }

      ///mobile/product/public/list => SQL TYPE PRODUCT LIST
      let url = extendedConfigs?.isElastic ? `/mobile/product/elastic/list` : `mobile/product/public/list`;
      let ret = await get(url, payload);

      if (ret && ret.data && Array.isArray(ret.data)) {
        if (props.returnDataCount) {
          props.returnDataCount(ret.totalData);
        }

        for (let [counter, item] of ret.data.entries()) {
          if (extendedConfigs?.isElastic) {
            var productAttribute = item.AttributeList.filter(function (attr) {
              if (attr.LanguageCode == languageCode.value) {
                return attr;
              }
            });
            let pickAttr = _.pick(productAttribute[0], ["Name", "Description"]);
            item = { ...item, ...pickAttr };
          }
          productList.value.push(item);
        }

        afterSearch.value = ret.afterSearch ? ret.afterSearch : "";

        isFetching.value = false;
        lastPage = ret?.lastPage;
        page++;

        setTimeout(() => {
          let $masonry = document.querySelector("#productMasonry");
          $masonry && $masonry.layout() ? $masonry.layout() : "";
        }, 1000);
      }

      if (props?.isLoader) {
        helpers.hideLoader();
      }
    };

    const region = (R, S) => {
      RegionId.value = R || "";
      SubRegionId.value = S || "";

      productList.value = [];
      page = 1;
      getData();
    };

    const loadMore = () => {
      if (lastPage >= page) {
        getData();
      }
    };

    const search = (data) => {
      searchValue.value = data;
      productList.value = [];
      page = 1;

      getData();
    };

    onMounted(() => {
      if (props?.f7route?.query?.search) searchValue.value = props?.f7route?.query?.search;
      if (props?.layout) selectedView.value = props?.layout;
      if (props?.f7route?.query?.w) selectedShippingMethod.value = props?.f7route?.query?.w;

      if (pageFilterData.value?.CategoryCode && pageFilterData.value?.CategoryCode != "" && pageFilterData.value.CountryCode == props.countryCode) {
        selectedCategory.value = pageFilterData.value;

        selectedFilterType.value = "C.CategoryCode";
        selectedFilterValue.value = pageFilterData.value.CategoryCode;
      }

      getData();
    });

    const changeView = (view) => {
      selectedView.value = view;
    };

    const openShippingPopup = ref(false);

    const onCloseShippingPopup = () => {
      openShippingPopup.value = false;
    };

    const selectShipping = () => {
      openShippingPopup.value = true;
    };

    const onShippingSelected = (data) => {
      onCloseShippingPopup();
      selectedShippingMethod.value = data;
      productList.value = [];
      page = 1;
      getData();
    };

    const onShippingCleared = (data) => {
      onCloseCategoryPopup();
      selectedShippingMethod.value = null;
      productList.value = [];
      page = 1;
      getData();
    };

    const openCategoryPopup = ref(false);

    const onCloseCategoryPopup = () => {
      openCategoryPopup.value = false;
    };

    const selectCategory = () => {
      openCategoryPopup.value = true;
    };

    const onCategorySelected = (data) => {
      onCloseCategoryPopup();

      if (data?.CategoryCode) {
        selectedCategory.value = data;

        store.dispatch("page/setData", {
          filter: {
            CategoryCode: data.CategoryCode,
          },
        });

        selectedFilterType.value = "C.CategoryCode";
        selectedFilterValue.value = data.CategoryCode;

        productList.value = [];
        page = 1;

        getData();
      }
    };

    const onCategoryCleared = (data) => {
      onCloseCategoryPopup();

      selectedCategory.value = "";
      store.dispatch("page/setData", {
        filter: {
          CategoryCode: "",
        },
      });

      selectedFilterType.value = "";
      selectedFilterValue.value = "";

      productList.value = [];
      page = 1;

      getData();
    };

    const openFilterPopup = ref(false);

    const onCloseFilterPopup = () => {
      openFilterPopup.value = false;
    };

    const showFilterPopUp = () => {
      openFilterPopup.value = true;
    };

    const onFilterSelected = (data) => {
      onCloseFilterPopup();
      let isReload = false;

      if (data?.Winery) {
        selectedWinery.value = data.Winery;
        isReload = true;
      }

      if (data?.FilterType && data?.FilterValue) {
        selectedFilterType.value = data.FilterType;
        selectedFilterValue.value = data.FilterValue;
        isReload = true;
      }
      if (isReload) {
        productList.value = [];
        page = 1;
        getData();
      }
    };

    const onFilterCleared = (data) => {
      selectedFilterType.value = "";
      selectedFilterValue.value = "";

      productList.value = [];
      page = 1;

      getData();
    };

    const onCloseSortPopup = () => {
      openSortPopup.value = false;
    };

    const showSortPopUp = () => {
      openSortPopup.value = true;
    };

    const onSortSelected = (data) => {
      onCloseSortPopup();
      sortValue.value = data.SortingValue;
      sortField.value = data.SortingField;
      sortProduct(data.SortingField, data.SortingValue, null);
      return;
    };

    const onSortCleared = (data) => {};

    const showSort = () => {
      openSortPopup.value = true;
    };

    const updateCategory = ({ CategoryCode, CountryCode }) => {
      page = 1;
      productList.value = [];

      selectedCategory.value = { CategoryCode };

      store.dispatch("page/setData", {
        filter: { CountryCode, CategoryCode },
      });

      getData();
    };

    const sortProduct = (sortField, sortValue, category) => {
      page = 1;
      productList.value = [];
      getData(category);
    };

    const updateSort = () => {
      if (sortValue.value == "ASC") {
        sortValue.value = "DESC";
      } else {
        sortValue.value = "ASC";
      }

      sortProduct("ProductPrice", sortValue.value, null);
    };

    const filterProduct = (field, value, category) => {
      page = 1;
      productList.value = [];
      selectedFilterType.value = "MULTIPLE";
      selectedFilterValue.value = value;
      getData(category);
    };

    return {
      region,
      RegionId,
      SubRegionId,

      isFetching,
      productList,
      loadMore,
      search,
      selectedView,
      changeView,
      selectedCategory,
      openCategoryPopup,
      onCloseCategoryPopup,
      selectCategory,
      onCategorySelected,
      onCategoryCleared,
      openFilterPopup,
      onCloseFilterPopup,
      showFilterPopUp,
      onFilterSelected,
      onFilterCleared,
      getData,
      updateCategory,
      sortProduct,
      filterProduct,
      updateSort,
      sortValue,
      sortField,
      onCloseSortPopup,
      showSortPopUp,
      onSortSelected,
      onSortCleared,
      openSortPopup,
      showSort,
      store,
      openShippingPopup,
      onCloseShippingPopup,
      onShippingSelected,
      onShippingCleared,
      selectShipping,
      selectedShippingMethod,
    };
  },
});
</script>
<style scoped>
.flexRight {
  justify-content: right;
}
.fullWidthContainer {
  width: calc(100% - 20px);
}

.no-padding-bottom {
  padding-bottom: 0px;
}
.no-padding-top {
  padding-top: 0px;
}
</style>
